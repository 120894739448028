<template>
  <div>
    <add-new-category :is-add-new-category-sidebar-active.sync="isAddNewCategorySidebarActive" :classId="classId"
      :subjectId="subjectId" @sendcategorydata="sendcategorydata" />
    <add-edit-category :is-add-edit-category-sidebar-active.sync="isAddEditCategorySidebarActive"
      :Editclassdata="Editclassdata" :Editsubjectid="Editsubjectid" :Editcategoryid="Editcategoryid"
      :Editcategoryname="Editcategoryname" :EditCategoryImage="EditCategoryImage" :editOrder="editOrder"
      :EditCategoryVideo="EditCategoryVideo" @clickEditCategorydata="clickEditCategorydata" />
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filters</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label for="class">Class</label>
            <v-select class="w-100" v-model="record_filters.class" placeholder="Select Class" :clearable="true"
              :reduce="(option) => option._id" :options="get_classes" label="className"
              @input="filterClassDropdown(record_filters.class)">
              <template v-slot:option="option">
                {{ option.className }}
              </template>
            </v-select>
          </b-col>
          <br />
          <b-col v-if="get_subjects.length > 0" cols="12" md="4" class="mb-md-0 mb-2">
            <label for="subject">Subject</label>
            <v-select class="w-100" v-model="record_filters.subject" placeholder="Select Subject" :clearable="true"
              :reduce="(option) => option._id" :options="get_subjects" label="subjectName"
              @input="filterSubjectDropdown(record_filters.subject)">
              <template v-slot:option="option">
                {{ option.subjectName }}
              </template>
            </v-select>
          </b-col>
          <b-col v-if="record_filters.subject" cols="12" md="4" class="mb-md-0 mb-2">
            <label for="subject">Topic Name</label>
            <button style="
                background: #9087F3;
                border-radius: 5px;
                margin-left: 10px;
                margin-top: -4px;
                position: absolute;
                right: 16px;
                bottom: 69px;
                color: white;
                border-color: white;
                font-weight: bold;
              " class="d-flex-row-end" v-if="removeButtons == true" @click="activeAddCategorySidebar()">
              <span class="text-nowrap">Add Topic</span>
            </button>
            <v-select class="w-100" v-model="record_filters.category" placeholder="Select Category" :clearable="true"
              :reduce="(option) => option._id" :options="get_categories" label="categoryName"
              @input="filterCategoryDropdown(record_filters.category)">
              <template v-slot:option="option">
                {{ option.categoryName }}
              </template>
            </v-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0 pb-2">
        <b-table-simple :key="refreshScreen" v-if="get_topics.total > 0" hover caption-top responsive
          class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>No.</b-th>
              <b-th>Topic Image</b-th>
              <b-th>Topic NAME</b-th>
              <b-th>Order</b-th>
              <b-th v-if="removeButtons == true">Android Access
                <div>
                  ({{ androidCount }})
                </div>
              </b-th>
              <b-th v-if="removeButtons == true">Iphone Access
                <div>
                  ({{ iosCount }})
                </div>
              </b-th>
              <b-th v-if="removeButtons == true">Ipad Access
                <div>
                  ({{ ipadCount }})
                </div>
              </b-th>
              <b-th v-if="removeButtons == true">Action</b-th>
              <b-th>Subject</b-th>
              <b-th>Class</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(item, index) in get_topics.data">
              <b-tr :key="index">
                <b-td>{{ index + 1 }}</b-td>
                <b-td>
                  <div v-if="item.subject">
                    <b-link :to="{ path: '/categorylevels/' + item._id }"
                      @click="sendPropsToLevel(item.className, item.subject.subjectName, item.categoryName, item._id)">
                      <img :src="item.categoryImg" alt="" @error="imgError" style="width: 100px; height: 90px" />
                    </b-link>
                  </div>
                </b-td>
                <b-td>
                  <div v-if="item.subject">
                    <div>
                      <b-link :to="{
                        path: '/categorylevels/' + item._id,
                      }" @click="sendPropsToLevel(
      item.className,
      item.subject.subjectName,
      item.categoryName,
      item._id
    )
      ">
                        {{ item.categoryName }}
                      </b-link>
                    </div>
                  </div>
                </b-td>
                <b-td>
                  <div v-if="item.subject">
                    <div>
                      <div v-if="item.order">
                        {{ item.order }}
                      </div>
                      <div v-else>N/A</div>
                    </div>
                  </div>
                </b-td>
                <b-td v-if="removeButtons == true">
                  <div v-if="item.subject">
                    <div>
                      <b-form-checkbox v-model="item.publicAccess" v-on:change="statusChangeAccess(item, 'public')"
                        switch size="lg"></b-form-checkbox>
                    </div>
                  </div>
                </b-td>
                <b-td v-if="removeButtons == true">
                  <div v-if="item.subject">
                    <div>
                      <b-form-checkbox v-model="item.iosAccess" v-on:change="statusChangeAccess(item, 'ios')" switch
                        size="lg"></b-form-checkbox>
                    </div>
                  </div>
                </b-td>
                <b-td v-if="removeButtons == true">
                  <div v-if="item.subject">
                    <div>
                      <b-form-checkbox v-model="item.ipadAccess" v-on:change="statusChangeAccess(item, 'ipad')" switch
                        size="lg"></b-form-checkbox>
                    </div>
                  </div>
                </b-td>
                <b-td v-if="removeButtons == true">
                  <b-dropdown variant="link" no-caret>
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <b-dropdown-item @click="activeEditCategorySidebar(
                      item.class._id,
                      item.subject._id,
                      item._id,
                      item.categoryName,
                      item.categoryImg,
                      item.order,
                      item.categoryVideo
                    )
                      ">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="activeDeleteCategorySidebar(
                      item._id,
                      item.subject._id,
                      item.subject.category._id
                    )
                      ">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-td>
                <b-td>
                  <div v-if="item.subject ? item.subject.subjectImg : ''">
                    <b-avatar class="mr-1" :src="item.subject.subjectImg" />
                  </div>
                  {{ item.subject ? item.subject.subjectName : "" }}
                </b-td>
                <b-td>
                  <div v-if="item.class.classImg">
                    <b-avatar class="mr-1" :src="item.class.classImg" />
                  </div>
                  <div>
                    {{ item.class.className }}
                  </div>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
        <div class="mx-2 mt-1">
          <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div class="dataTables_info mt-1">Showing {{ get_topics.from }} to {{ get_topics.to }} of {{
              get_topics.total }}
              entries</div>
            <!-- <pagination class="mt-1 mb-0" :data="get_topics" :limit=2 @pagination-change-page="getTopics"></pagination> -->
            <b-pagination v-model="currentPage" :total-rows="get_topics.total" :limit=4 :per-page="get_topics.per_page"
              @change="getTopics" class="mt-1 mb-0">
            </b-pagination>

          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BOverlay,
  BLink,
  BBadge,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTableSimple,
  BThead,
  BFormCheckbox,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import AddNewCategory from "./app/list/users-list/AddNewCategory.vue";
import AddEditCategory from "./app/list/users-list/AddEditCategory.vue";
import axios from "axios";
import * as _ from "lodash";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    AddNewCategory,
    BOverlay,
    AddEditCategory,
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTableSimple,
    BFormCheckbox,
    BThead,
    BTr,
    BTh,
    ToastificationContent,
    BTd,
    BTbody,
    BTfoot,
    BFormInput,
    vSelect,
    AddNewCategory,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      table_data: {},
      demo_table_data: {},
      classId: "",
      show: false,
      refreshScreen: 0,
      subjectId: "",
      showtableData: "false",
      Editclassdata: "",
      Editclassname: "",
      Editsubjectid: "",
      Editsubjectname: "",
      Editcategoryid: "",
      Editsubcategoryid: "",
      Editsubcategoryname: "",
      Editcategoryname: "",
      Editclassimg: "",
      Editsubjectimg: "",
      FixCategoryname: "",
      EditCategoryImage: "",
      EditCategoryVideo: {},
      EditSubcategoryImage: "",
      deleteclassdata: {
        id: "",
      },
      deletesubjectdata: {
        class_id: "",
        subject_id: "",
      },
      deletecategorydata: {
        classid: "",
        subjectid: "",
        categoryid: "",
      },
      deletesubcategory: {
        classid: "",
        subjectid: "",
        categoryid: "",
        subcategoryid: "",
      },
      editOrder: "",
      levels: {
        classid: "",
        subjectid: "",
        categoryid: "",
        subcategoryid: "",
      },
      subcategoryclass_id: "",
      subcategorysubject_id: "",
      subcategoryparent_id: "",
      subcategorychild_id: "",
      isAddNewSubjectSidebarActive: false,
      isAddNewUserSidebarActive: false,
      isAddNewCategorySidebarActive: false,
      isAddEditClassSidebarActive: false,
      isAddEditSubjectSidebarActive: false,
      isAddEditCategorySidebarActive: false,
      isAddNewSubCategorySidebarActive: false,
      isAddEditSubCategorySidebarActive: false,
      isAddNewLevelSidebarActive: false,
      classFilter: [],
      subjectFilter: [],
      categoryFilter: [],
      subjectFilterBarShow: false,
      categoryFilterBarShow: false,
      perPage: 5,
      searchQuery: null,
      perPageOptions: [10, 25, 50, 100],
      get_class_data: {},
      alertData: "",
      classOption: "",
      subjectOption: "",
      categoryOption: "",
      demoSubjectData: [],
      demoCategoryData: [],
      classFilterArr: [],
      subjectFilterArr: [],
      classValue: false,
      GlobalSubjectId: "",
      GlobalClassId: "",
      removeButtons: false,
      record_filters: {},
      get_topics: {},
      get_classes: [],
      get_subjects: [],
      get_categories: [],
      androidCount: null,
      iosCount: null,
      ipadCount: null,
    };
  },
  mounted() {
    this.removeButtons = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "manage_topics");
  },
  created() {
    this.record_filters.class = this.$route.query.class;
    this.record_filters.subject = this.$route.query.subject;
    this.record_filters.category = this.$route.query.category;
    this.getTopics();
  },
  methods: {
    imgError(event) {
      event.target.src = 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp';
    },
    statusChangeAccess(category, accessType) {
      let status = '';
      if (accessType === 'ios') {
        status = category.iosAccess
      } else if (accessType === 'public') {
        status = category.publicAccess
      } else if (accessType === 'ipad') {
        status = category.ipadAccess
      }

      axios.post(
        `${process.env.VUE_APP_API_URL}/category/access/update`,
        {
          _id: category._id,
          accessType,
          status
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("_t")}`,
          }
        }
      )
        .then(response => {
          if (response.data.status) {
            this.alertData = response.data.message;
            this.showToast("success");
          }
        })
        .catch(error => {
          this.alertData = error.response.data.error || 'An error occurred';
          this.showToast("danger");
          this.getTopics();
        });
    },
    checkCategory(i) {
      if (i != null) {
        if (i == "All Category") {
        } else {
          var categoryName = {
            name: i,
            user: JSON.parse(localStorage.getItem("Datauser")),
          };
          axios
            .post(
              process.env.VUE_APP_API_URL + "/topic/user/save",
              categoryName,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("_t"),
                },
              }
            )
            .then((response) => { });
          var categoryArray = [];
          var finalObjecttArr = [];
          for (let index = 0; index < this.demoCategoryData.length; index++) {
            if (this.classOption == this.demoCategoryData[index].className) {
              if (
                this.demoCategoryData[index].subject.subjectName ==
                this.subjectOption
              ) {
                if (this.demoCategoryData[index].subject.category) {
                  if (
                    this.demoCategoryData[index].subject.category
                      .categoryName == i
                  ) {
                    finalObjecttArr.push(this.demoCategoryData[index]);
                  }
                }
              }
            }
          }

          this.table_data = finalObjecttArr;
        }
      } else {
        var categoryName = {
          name: i,
          user: JSON.parse(localStorage.getItem("Datauser")),
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/topic/user/save",
            categoryName,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => { });

        this.table_data = this.demoCategoryData;
      }
      this.forcRenderSubject();
    },
    filterClass() {
      axios
        .get(process.env.VUE_APP_API_URL + "/grade/all")
        .then((get_response) => {
          this.classFilterArr = get_response.data;
          for (let index = 0; index < this.classFilterArr.length; index++) {
            this.classFilter.push(this.classFilterArr[index].className);
          }
          if (get_response) {
            this.classValue = true;
          }
        });
    },
    SubjectcheckFunction(i) {
      this.categoryOption = "";
      if (i != null) {
        if (i == "All Subjects") {
        } else {
          var subjectName = {
            name: i,
            user: JSON.parse(localStorage.getItem("Datauser")),
          };
          axios
            .post(
              process.env.VUE_APP_API_URL + "/subject/user/save",
              subjectName,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("_t"),
                },
              }
            )
            .then((response) => { });
          this.categoryFilter = [];
          var subjectArray = [];
          var finalsubjectArr = [];
          var newObject = {};
          var newSubject = {};
          for (let index = 0; index < this.demoSubjectData.length; index++) {
            if (this.classOption == this.demoSubjectData[index].className) {
              this.GlobalClassId = this.demoSubjectData[index]._id;
              if (this.demoSubjectData[index].subject.subjectName == i) {
                this.GlobalSubjectId = this.demoSubjectData[index].subject._id;
                newObject["_id"] = this.demoSubjectData[index]._id;
                newObject["className"] = this.demoSubjectData[index].className;
                newObject["classImg"] = this.demoSubjectData[index].classImg;
                newSubject["_id"] = this.demoSubjectData[index].subject._id;
                newSubject["subjectName"] =
                  this.demoSubjectData[index].subject.subjectName;
                newSubject["subjectImg"] =
                  this.demoSubjectData[index].subject.subjectImg;
                newObject["subject"] = newSubject;
                finalsubjectArr.push(this.demoSubjectData[index]);
                if (this.demoSubjectData[index].subject.category) {
                  this.categoryFilter.push(
                    this.demoSubjectData[index].subject.category.categoryName
                  );
                }
              }
            }
          }
          finalsubjectArr.push(newObject);
          this.table_data = finalsubjectArr;
          this.demoCategoryData = this.table_data;
          this.categoryFilterBarShow = true;
        }
      } else {
        var subjectName = {
          name: i,
          user: JSON.parse(localStorage.getItem("Datauser")),
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/subject/user/save",
            subjectName,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => { });
        this.categoryFilter = [];
        var subjectArray = [];

        this.table_data = this.demoSubjectData;

        this.categoryFilterBarShow = true;
        this.categoryFilterBarShow = false;
        this.categoryfunction();
      }
      this.forcRenderSubject();
    },
    categoryfunction() {
      var categoryName = {
        name: null,
        user: JSON.parse(localStorage.getItem("Datauser")),
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/topic/user/save", categoryName, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => { });
    },
    subjectfilterdata(className) {
      let classId = { id: "" };
      for (let index = 0; index < this.classFilterArr.length; index++) {
        if (className == this.classFilterArr[index].className) {
          classId.id = this.classFilterArr[index]._id;
        }
      }
      if (classId.id != "") {
        axios
          .post(process.env.VUE_APP_API_URL + "/getSubject", classId)
          .then((response) => {
            this.subjectFilterArr = response.data;
            for (let index = 0; index < this.subjectFilterArr.length; index++) {
              this.subjectFilter.push(this.subjectFilterArr[index].subjectName);
            }
          });
      }
    },
    checkClass(i) {
      if (i != null) {
        this.categoryOption = "";
        this.table_data = {};
        this.subjectOption = "";
        this.demoSubjectData = [];
        this.demoCategoryData = [];
        var alltabledata = JSON.parse(localStorage.getItem("demo_table_data"));
        if (i == "All Classes") {
          var className = {
            name: i,
            user: JSON.parse(localStorage.getItem("Datauser")),
          };
          axios
            .post(process.env.VUE_APP_API_URL + "/class/user/save", className, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            })
            .then((response) => { });

          this.subjectFilter = [];
          var classArray = [];
          for (let index = 0; index < alltabledata.length; index++) {
            classArray.push(alltabledata[index]);
            if (alltabledata[index].subject) {
              this.subjectFilter.push(alltabledata[index].subject.subjectName);
            }
          }
          this.subjectFilter = [...new Set(this.subjectFilter)];
          this.table_data = classArray;
          this.demoSubjectData = this.table_data;
          this.categoryFilterBarShow = false;
          this.subjectFilterBarShow = false;
          this.showtableData = true;
        } else {
          var className = {
            name: i,
            user: JSON.parse(localStorage.getItem("Datauser")),
          };
          axios
            .post(process.env.VUE_APP_API_URL + "/class/user/save", className, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            })
            .then((response) => { });

          this.subjectFilter = [];
          var classArray = [];
          for (let index = 0; index < alltabledata.length; index++) {
            if (alltabledata[index].className == i) {
              classArray.push(alltabledata[index]);
              this.subjectFilter.push(alltabledata[index].subject.subjectName);
            }
          }

          this.subjectFilter = [...new Set(this.subjectFilter)];

          this.table_data = classArray;
          this.demoSubjectData = this.table_data;
          this.categoryFilterBarShow = false;
          this.subjectFilterBarShow = true;
          this.showtableData = true;
        }
      } else {
        var className = {
          name: i,
          user: JSON.parse(localStorage.getItem("Datauser")),
        };
        axios
          .post(process.env.VUE_APP_API_URL + "/class/user/save", className, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((response) => { });
        var alltabledata = JSON.parse(localStorage.getItem("demo_table_data"));

        this.table_data = alltabledata;
        this.subjectFilterBarShow = false;
        this.categoryFilterBarShow = false;
        this.showtableData = false;
      }
      this.subjectDbromve();
      this.forcRenderSubject();
    },
    subjectDbromve() {
      var subjectName = {
        name: null,
        user: JSON.parse(localStorage.getItem("Datauser")),
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/subject/user/save", subjectName, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => { });
    },
    forcRenderSubject() {
      this.refreshScreen += 1;
    },
    clickedShowDetailModal: function (class_data) {
      axios
        .post(process.env.VUE_APP_API_URL + "/postclassdata", class_data)
        .then((response) => {
          if (response.data.message == "class added") {
            this.classAdded("success");
            this.gettabledata();
          } else {
            this.classAddedRejected("danger");
          }

          // this.gettabledata();
        });
    },
    classAdded(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Class Added",
          variant,
        },
      });
    },
    classAddedRejected(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "class not added",
          variant,
        },
      });
    },
    subjectAdded(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Subject Added",
          variant,
        },
      });
    },
    subjectAddedRejected(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Subject not added",
          variant,
        },
      });
    },
    setPermission() {
      if (localStorage.getItem("_t")) {
        axios
          .get(process.env.VUE_APP_API_URL + "/tokencheck", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            if (get_response.data.message == "authenticated") {
              var permissiondata = JSON.stringify(get_response.data.permission);
              if (permissiondata) {
                localStorage.setItem("permission", permissiondata);
                this.gettabledata();
              }
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.data.message == "Unauthenticated.") {
                localStorage.removeItem("permission", { permission: [] });
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      }
    },
    sendcategorydata: function (category_data) {
      let token = localStorage.getItem("_t");
      if (token) {
        this.show = true;
        axios
          .post(
            process.env.VUE_APP_API_URL + "/subject-topic/add",
            category_data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            this.alertData = "Topic Successfully Added";
            this.showToasted("success");
            this.getTopics();
            this.show = false;
          })
          .catch(function (error) {
            this.alertData = "Try Again Topic Not Added";
            this.showToasted("danger");
            this.show = false;
          });
      }
    },
    gettabledata: function () {
      this.show = true;
      this.classFilter = [];
      this.table_data = {};
      axios
        .get(process.env.VUE_APP_API_URL + "/subject/category/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.table_data = get_response.data;
          this.show = false;
          let p_data = JSON.parse(localStorage.getItem("permission"));
          localStorage.setItem(
            "demo_table_data",
            JSON.stringify(this.table_data)
          );
          for (let index = 0; index < this.table_data.length; index++) {
            this.classFilter.push(this.table_data[index].className);
          }
          this.classFilter = [...new Set(this.classFilter)];
          this.classFilter.push("All Classes");
          if (
            this.$route.query &&
            this.$route.query.class &&
            this.$route.query.subject
          ) {
            if (this.$route.query.class.length != 0) {
              if (this.$route.query.subject.length != 0) {
                this.checkClass(this.$route.query.class);
                this.classOption = this.$route.query.class;
                this.SubjectcheckFunction(this.$route.query.subject);
                this.subjectOption = this.$route.query.subject;
              } else {
              }
            } else {
            }
          } else {
            if (p_data.user.className != null) {
              this.checkClass(p_data.user.className);
              this.classOption = p_data.user.className;
              if (p_data.user.subjectName != null) {
                this.SubjectcheckFunction(p_data.user.subjectName);
                this.subjectOption = p_data.user.subjectName;
                if (p_data.user.categoryName != null) {
                  this.checkCategory(p_data.user.categoryName);
                  this.categoryOption = p_data.user.categoryName;
                }
              }
            }
          }
        });
    },
    clickeditclassdata: function (edit_class_data) {
      axios
        .post(process.env.VUE_APP_API_URL + "/editclassdata", edit_class_data)
        .then((response) => {
          this.gettabledata();
        });
    },
    clickEditCategorydata: function (edit_category_data) {
      let token = localStorage.getItem("_t");
      if (token) {
        this.show = true;
        axios
          .post(
            process.env.VUE_APP_API_URL + "/subject-topic/edit",
            edit_category_data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            this.alertData = "Topic Successfully Edit";
            this.showToasted("success");
            this.getTopics();
          })
          .catch(function (error) {
            this.alertData = "Try Again Topic Not Edit";
            this.showToasted("danger");
            this.show = false;
          });
      }
    },
    activeDeleteClassSidebar: function (classdelete) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteclassdata.id = classdelete;
          axios
            .post(
              process.env.VUE_APP_API_URL + "/deleteclassdata",
              this.deleteclassdata
            )
            .then((response) => {
              if (response.data == "Class not delete") {
                this.alertData =
                  response.data + ".Please delete all the Subject first";
              } else {
                this.alertData = "Class deleted";
              }
              this.showToasted("danger");
              this.gettabledata();
            });
        }
      });
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    activeDeleteSubjectSidebar: function (class_id, subject_id) {
      this.deletesubjectdata.class_id = class_id;
      this.deletesubjectdata.subject_id = subject_id;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/deletesubjectdata",
          this.deletesubjectdata
        )
        .then((response) => {
          if (response.data == "Subject not delete") {
            this.alertData =
              response.data + ".Please delete all the Category first";
          } else {
            this.alertData = "Subject deleted";
          }
          this.showToast("danger");
          this.gettabledata();
        });
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    activeDeleteCategorySidebar: function (class_id, subject_id, category_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletecategorydata.classid = class_id;
          this.deletecategorydata.subjectid = subject_id;
          this.deletecategorydata.categoryid = category_id;
          let token = localStorage.getItem("_t");
          if (token) {
            this.show = true;

            axios
              .post(
                process.env.VUE_APP_API_URL + "/subject-topic/delete",
                this.deletecategorydata,
                {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                }
              )
              .then((response) => {
                if (response.data == "Category not delete") {
                  this.alertData =
                    response.data + ".Please delete all the level first";
                  this.show = false;
                  this.showT("danger");
                } else {
                  this.alertData = "Category deleted";
                  this.showT("danger");
                  this.show = false;
                  this.gettabledata();
                }
              });
          }
        }
      });
    },
    showT(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    activeDeleteSubCategorySidebar: function (
      class_id,
      subject_id,
      category_id,
      subcategory_id
    ) {
      this.deletesubcategory.classid = class_id;
      this.deletesubcategory.subjectid = subject_id;
      this.deletesubcategory.categoryid = category_id;
      this.deletesubcategory.subcategoryid = subcategory_id;

      axios
        .post(
          process.env.VUE_APP_API_URL + "/deletesubcategorydata",
          this.deletesubcategory
        )
        .then((response) => {
          this.gettabledata();
        });
    },
    activeAddSubjectSidebar(classId) {
      this.isAddNewSubjectSidebarActive = true;
      this.classId = classId;
    },
    activeAddCategorySidebar() {
      this.classId = this.record_filters.class;
      this.subjectId = this.record_filters.subject;
      this.isAddNewCategorySidebarActive = true;
    },
    activeEditClassSidebar(edit_class, edit_name, edit_img) {
      this.isAddEditClassSidebarActive = true;
      this.Editclassdata = edit_class;
      this.Editclassname = edit_name;
      this.Editclassimg = edit_img;
    },
    activeEditSubjectSidebar(
      editclassid,
      editsubjectid,
      editsubjectname,
      editsubjectimg
    ) {
      this.isAddEditSubjectSidebarActive = true;
      this.Editclassdata = editclassid;
      this.Editsubjectid = editsubjectid;
      this.Editsubjectname = editsubjectname;
      this.Editsubjectimg = editsubjectimg;
    },
    activeEditCategorySidebar(
      editclassid,
      editsubjectid,
      editcategoryid,
      editcategoryname,
      editcatgeoryimage,
      editOrderCategroy,
      categoryVideo
    ) {
      this.isAddEditCategorySidebarActive = true;
      this.Editclassdata = editclassid;
      this.Editsubjectid = editsubjectid;
      this.Editcategoryid = editcategoryid;
      this.Editcategoryname = editcategoryname;
      if (editOrderCategroy == undefined) {
        this.editOrder = "";
      } else {
        this.editOrder = editOrderCategroy;
      }
      this.EditCategoryImage = editcatgeoryimage;
      this.EditCategoryVideo = categoryVideo;
    },
    activeAddSubcategorySidebar(
      subcategoryclassid,
      subcategorysubjectid,
      subcategoryparentid
    ) {
      this.isAddNewSubCategorySidebarActive = true;
      this.subcategoryclass_id = subcategoryclassid;
      this.subcategorysubject_id = subcategorysubjectid;
      this.subcategoryparent_id = subcategoryparentid;
    },
    levelscreendata(gradeid, subjectid, categoryid, subcategoryid) {
      this.levels = {
        classid: gradeid,
        subjectid: subjectid,
        categoryid: categoryid,
        subcategoryid: subcategoryid,
      };
    },
    sendPropsToLevel(classNAme, subjectName, categoryName, catgeoryid) {
      let a = JSON.stringify(classNAme);
      localStorage.setItem("className", a);
      let b = JSON.stringify(subjectName);
      localStorage.setItem("subjectName", b);
      let c = JSON.stringify(categoryName);
      localStorage.setItem("categoryName", c);
      let d = JSON.stringify(catgeoryid);
      localStorage.setItem("categoryId", d);
    },
    activeEditSubCategorySidebar(
      class_id,
      subject_id,
      category_id,
      category_name,
      sub_category_id,
      sub_category_name,
      sub_category_img
    ) {
      this.isAddEditSubCategorySidebarActive = true;
      this.Editclassdata = class_id;
      this.Editsubjectid = subject_id;
      this.Editcategoryid = category_id;
      this.FixCategoryname = category_name;
      this.Editsubcategoryid = sub_category_id;
      this.Editsubcategoryname = sub_category_name;
      this.EditSubcategoryImage = sub_category_img;
    },

    filterClassDropdown() {
      this.record_filters.subject = null;
      this.record_filters.category = null;
      this.getTopics();
    },
    filterSubjectDropdown() {
      this.record_filters.category = null;
      this.getTopics();
    },
    filterCategoryDropdown() {
      let category = this.get_categories.find(get_categories => get_categories._id === this.record_filters.category);
      if (category) {
        this.record_filters.subject = category.subjectId;
      } else {
        this.record_filters.category = null;
      }
      this.getTopics();
    },

    getTopics: function (page = 1) {
      var url = process.env.VUE_APP_API_URL + `/topics?page=${page}`;
      const payload = this.record_filters
      this.show = true;
      this.androidCount = null;
      this.iosCount = null;
      this.ipadCount = null;
      axios
        .post(url, payload, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.androidCount = res.data.androidCount;
          this.iosCount = res.data.iosCount;
          this.ipadCount = res.data.ipadCount;
          this.get_topics = res.data.topics;

          this.get_classes = res.data.classes;
          this.get_classes = [...new Set(this.get_classes)];
          this.get_classes.push("All Classes");

          this.get_subjects = res.data.subjects;
          this.get_categories = res.data.categories;

          this.show = false;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.add-catrgory-button {
  margin-top: 10px;
}
</style>
<style>
.vs__open-indicator {
  fill: #9d9d9d !important;
}
</style>
